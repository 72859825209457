import React from "react";

import Layout from "../components/organisms/layout";
import Footer from "../components/organisms/footer";
import Header from "../components/organisms/header";
import Contact from "../components/organisms/contact";

import Nav from "../components/molecules/nav";
import SEO from "../components/molecules/seo";
import CollapsablePanel from "../components/molecules/collapsable-panel";

import "../scss/molecules/_panel.scss";

import headerImage from "../../static/design/img/header/pages/ritorno-art-deco.jpg";

const Faq = () => (
  <Layout>
    <SEO
      title="FAQ"
      description="Heeft u toch nog vragen? Bekijk gerust de veelgestelde vragen. En ontdek waarom ons pizzakraam op uw feest thuishoort."
    />

    <Nav />

    <Header heading="De veelgestelde vragen" image={headerImage} />

    <section
      className="pattern-bg py-8"
      style={{ backgroundImage: "url(/design/img/pattern-background.png)" }}
    >
      <div className="container">
        <h2 className="title text-center">FAQ</h2>

        <CollapsablePanel title="Op welk soort feesten worden jullie gevraagd?">
          <p>
            Wij worden veelzijdig gevraagd op <em>gezins -en familiefeesten</em>
            . Denk hierbij aan communies, verjaardagen, huwelijken, afstuderen,
            pensionering, baby-shower, enz. We verzorgden ook reeds feesten bij
            <em>vrienden en (sport)clubs</em>.
          </p>
          <p>
            <em>Bedrijfsevents</em> vormen voor ons een zeer belangrijke
            doelgroep. Wij zijn niet alleen qua prijzen erg concurrentieel, maar
            kunnen ook grotere aantallen genodigden met gemak aan. Bovendien
            zijn een meerderheid van de bedrijven die met ons in zee gaan,
            bijzonder gecharmeerd door ons aanbod van voor -en nagerechten, wat,
            in hun woorden, hun event vervolledigd. Ook onze professionele en
            efficiënte aanpak kan op hun waardering rekenen.
          </p>
        </CollapsablePanel>
        <CollapsablePanel title="Vanaf welk minimum aantal genodigden komen jullie een feest verzorgen?">
          <p>
            Wij komen reeds vanaf 20 personen. Wij aanvaarden trouwens
            opdrachten in gans België. Wij zijn trouwens ook ontzettend trots
            over dat onze zeer stipte en accurate communicatie.. Wij begrijpen
            als geen ander dat het feest dat u organiseert wel degelijk voor u
            een uiterst betekenisvol gebeuren is.
          </p>
        </CollapsablePanel>
        <CollapsablePanel title="Waar plaatsen jullie het pastakraam?">
          <p>
            Onze zeer mooie buffettafel en overkapping zijn volledig
            demonteerbaar. Een smalle deuropening, enkele trappen of grind
            vormen geen probleem. De overkapping neemt zo’n 3m op 3m in en zorgt
            ervoor dat regen geen spelbreker vormt.
          </p>
        </CollapsablePanel>
        <CollapsablePanel title="Hoelang duurt het pastafeest?">
          <p>
            U bepaalt, uiteraard, het moment dat wij uw gasten de pastagerechten
            voorschotelen. Twee uur eerder dan dit afgesproken tijdstip, komen
            wij bij u toe. Al direct bekijken wij samen met u, welke de beste
            plek is voor de opstelling van het kraam. Wij trachten steeds om
            onze gezellige buffettafel zo dicht mogelijk bij de genodigden te
            plaatsen. Het rustig eten van de pastagerechten neemt ongeveer zo’n
            anderhalf uur in beslag. Ieder krijgt ruim de tijd om zoveel keer
            als gewild een nieuwe pastabeker op te halen. Per slot van rekening
            zijn er 18 pasta/sauscombinaties die wij uw genodigden
            voorschotelen. Na het pastafestijn beginnen wij onmiddellijk met de
            opruim. Onze aanwezigheid op uw feest neemt tussen de drie en vier
            uur in beslag, afhankelijk van het feit of er voor- en/of
            nagerechten besteld werden. In ieder geval, wij garanderen u dat er
            na ons vertrek geen spoortje meer te vinden zal zijn van ons
            optreden, … tenzij bij uw gasten die genoten hebben van wat u ze in
            de vorm van een uiterst gezellig pastafeest, voorgeschoteld hebt.
          </p>
        </CollapsablePanel>
        <CollapsablePanel title="Hebben jullie enige voorzieningen nodig?">
          <p>
            Wij hebben helemaal niets nodig van voorzieningen. Ons pastakraam
            werkt op basis van gasflessen die we zelf voorzien. Zelfs het water
            voor in te koken nemen we zelf mee. Zo hoeven jullie zich over
            helemaal niets zorgen te maken.
          </p>
        </CollapsablePanel>
      </div>
    </section>
    <Contact dark="true" />

    <Footer />
  </Layout>
);

export default Faq;
